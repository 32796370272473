import React, { useState, useRef } from 'react';
import emailjs from "@emailjs/browser";

function FeedbackForm({ mode, lan }) {
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useRef(null);

  const handleSendEmail = (e) => {
    e.preventDefault();
    console.log("Sending email with the following data:", { name, feedback });
    if (!name || !feedback) {
      setModalMessage(
        lan === 'English'
          ? 'يرجى ملء جميع الحقول.'
          : 'Please fill in all fields.'
      );
      setModalType('error');
      setIsModalOpen(true);
      return;
    }

    setIsSending(true);

    emailjs
      .sendForm(
        'service_bgs34wk',
        'template_5w6aehg',
        form.current,
        'jm56_ibSKRykP9dsR'
      )
      .then(
        () => {
          setIsSending(false);
          setModalMessage(
            lan === 'English'
              ? 'تم إرسال الرسالة بنجاح!'
              : 'Message sent successfully!'
          );
          setModalType('success');
          setIsModalOpen(true);
          setName('');
          setFeedback('');
        },
        () => {
          setIsSending(false);
          setModalMessage(
            lan === 'English'
              ? 'فشل في إرسال الرسالة. يرجى المحاولة مرة أخرى.'
              : 'Failed to send the message. Please try again.'
          );
          setModalType('error');
          setIsModalOpen(true);
        }
      );
  };

  return (
    <div id="feedback-form" style={styles.container(mode)}>
      <h2 style={styles.title(mode, lan)}>
        {lan === 'English' ? '!أفكارك تهمنا' : 'Your Thoughts Matter!'}
      </h2>
      <form ref={form} onSubmit={handleSendEmail} style={styles.form(mode, lan)}>
        <div style={styles.inputGroup}>
          <label htmlFor="name" style={styles.label(mode, lan)}>
            {lan === 'English' ? ':اسمك' : 'Your Name:'}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              ...styles.input(mode),
              textAlign: lan === 'English' ? 'right' : 'left',
            }}
            placeholder={lan === 'English' ? 'أدخل اسمك' : 'Enter your name'}
          />
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="feedback" style={styles.label(mode, lan)}>
            {lan === 'English' ? ':ملاحظاتك' : 'Your Feedback:'}
          </label>
          <textarea
            name="message"
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows="4"
            style={{
              ...styles.textarea(mode),
              textAlign: lan === 'English' ? 'right' : 'left',
            }}
            placeholder={
              lan === 'English' ? 'أدخل ملاحظاتك' : 'Enter your feedback'
            }
          />
        </div>
        <button type="submit" style={styles.button(mode)} disabled={isSending}>
          {isSending
            ? lan === 'English'
              ? 'إرسال...'
              : 'Sending...'
            : lan === 'English'
            ? 'إرسال'
            : 'Submit Feedback'}
        </button>
      </form>

      {isModalOpen && (
        <div style={styles.modal(modalType)}>
          <p>{modalMessage}</p>
          <button style={styles.closeButton} onClick={() => setIsModalOpen(false)}>
            Close
          </button>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: (mode) => ({
    backgroundColor: mode === 'Light' ? '#f9f9f9' : '#333',
    color: mode === 'Light' ? '#333' : '#f9f9f9',
    borderRadius: '10px',
    padding: '20px',
    maxWidth: '400px',
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
    boxShadow:
      mode === 'Light'
        ? '0 4px 8px rgba(0, 0, 0, 0.3)'
        : '0 4px 8px rgba(255, 255, 255, 0.1)',
    transition: 'all 0.3s ease',
    width: '80%',
    position: 'relative',
  }),
  title: (mode, lan) => ({
    textAlign: lan === 'English' ? 'right' : 'left',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#75d840',
  }),
  form: (mode, lan) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: lan === 'English' ? 'flex-end' : 'flex-start',
  }),
  inputGroup: {
    marginBottom: '15px',
    width: '100%',
  },
  label: (mode, lan) => ({
    fontSize: '16px',
    marginBottom: '5px',
    display: 'block',
    color: mode === 'Light' ? '#333' : '#f9f9f9',
    textAlign: lan === 'English' ? 'right' : 'left',
  }),
  input: (mode) => ({
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: mode === 'Light' ? '#ccc' : '#555',
    backgroundColor: mode === 'Light' ? '#fff' : '#444',
    color: mode === 'Light' ? '#333' : '#f9f9f9',
    fontSize: '16px',
    outline: 'none',
    transition: 'border 0.3s',
  }),
  textarea: (mode) => ({
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: mode === 'Light' ? '#ccc' : '#555',
    backgroundColor: mode === 'Light' ? '#fff' : '#444',
    color: mode === 'Light' ? '#333' : '#f9f9f9',
    fontSize: '16px',
    outline: 'none',
    resize: 'none',
    transition: 'border 0.3s',
  }),
  button: (mode) => ({
    padding: '10px 20px',
    backgroundColor: '#75d840',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    width: '50%',
    alignSelf: 'center',
    opacity: mode === 'Light' ? 1 : 0.9,
  }),
  modal: (type) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: type === 'success' ? '#d4edda' : '#f8d7da',
    color: type === 'success' ? '#155724' : '#721c24',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    textAlign: 'center',
  }),
  closeButton: {
    marginTop: '10px',
    backgroundColor: '#75d840',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
};

export default FeedbackForm;
