import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import ExampleCarouselImage from "./image/ExampleCarouselImage";
import FeedbackForm from "./FeedbackForm";
import { Link } from "react-router-dom"; // Import the Link component
import { FaMobileAlt, FaBell, FaChartBar, FaCloud, FaThermometerHalf } from "react-icons/fa";
import { GiRobotGrab } from "react-icons/gi";
import "../styles/Icons.css"
import "../styles/Farming.css"
import "../styles/About.css"
const Farming = ({ lan, mode }) => {
  const [isHovered, setIsHovered] = useState(false); // State to track hover

  const scrollToAbout = () => {
    const aboutSection = document.getElementById("about-section");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const heroText =
    lan === "English"
      ? "ثورة في تربية الدواجن مع حلول إنترنت الأشياء(IoT)"
      : "Revolutionizing Poultry Farming with IoT Solutions";
  const heroSubText =
    lan === "English"
      ? "قم بتحسين وإدارة مزرعتك للدواجن بسهولة مع حلولنا الذكية القائمة على إنترنت الأشياء، مما يضمن صحة أفضل للقطيع وكفاءة محسنة للمزرعة."
      : "Optimize and manage your poultry farm effortlessly with our smart IoT-based solution, ensuring healthier flocks and improved farm efficiency.";
  const heroButtonText = lan === "English" ? "المزيد" : "More";

  const isArabic = lan === "English";

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor: mode === "Light" ? "#f8f9fa" : "#3C3D37",
      }}>
      <div className="heroSection"
        style={{
          
          justifyContent: isArabic ? "end" : "start",
        }}>
        <div className="heroContent">
          <h1
          className="heroText"
            style={{
              textAlign: isArabic ? "right" : "left",
              color: "#FFFAB7",
              direction: lan !== "English" ? "ltr" : "rtl",
            }}>
            {heroText}
          </h1>
          <p
          className="heroSubText"
            style={{
              textAlign: isArabic ? "right" : "left",
              direction: lan !== "English" ? "ltr" : "rtl",
            }}>
            {heroSubText}
          </p>
          <button
            style={{
              ...styles.moreButton,
              color: mode === "Light" ? "white" : "#000000",
            }}
            onClick={scrollToAbout}>
            {heroButtonText}
          </button>
        </div>
      </div>

      <div style={styles.carouselContainer}>
        <Carousel style={{height:"500px"}}>
        <Carousel.Item interval={3000}>
            <ExampleCarouselImage
              imageUrl="../../images/Network.jpg"
            />
            <Carousel.Caption
              style={{
                textAlign: isArabic ? "right" : "left",  // Aligns the text based on language
                justifySelf: isArabic ? "start" : "end",  // Justifies text within the container
                position: "absolute",  // Ensures positioning within the carousel
                top: "10px",  // Adjust the top value as per your design
                right: isArabic ? "10px" : "auto",  // Position to the right for Arabic
                left: !isArabic ? "10px" : "auto"  ,
                padding:"40px" // Position to the left for English
              }}>
            <h1>{lan ==="English" ? "الوصول السريع لمزرعتك" : "Quick Access to Your Farm"}</h1>
<p     style={{
              fontSize: "20px",
              fontWeight: "500",
              direction: lan !== "English" ? "ltr" : "rtl",
              
            }}>
  {lan ==="English"
 ? "مع Agribits، ابقَ على اتصال بمزرعتك من أي مكان."
 : "With Agribits, stay connected to your farm from anywhere."}    
  
</p>

            </Carousel.Caption>
          </Carousel.Item>
          
          <Carousel.Item interval={3000}>
            <ExampleCarouselImage
              imageUrl="../../images/poultry.png"
            />
            <Carousel.Caption
              style={{
                textAlign: isArabic ? "right" : "left",
                marginBottom:"20px",
              }}>
              <h1>{lan === "English" ? "IoT تحسين المزرعة باستخدام" : "Enhancing Farming with IoT"}</h1>
<p
  style={{
    fontSize: "20px",
    fontWeight: "500",
    direction: lan !== "English" ? "ltr" : "rtl",
  }}>
  {lan === "English"
    ? "مع حلول إنترنت الأشياء (IoT )من Agribits، يمكنك مراقبة وتحليل بيانات مزرعتك في الوقت الفعلي لتحسين الإنتاجية."
    : "With Agribits' IoT solutions, you can monitor and analyze your farm's data in real-time to optimize productivity."}
</p>

            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <ExampleCarouselImage
              imageUrl="../../images/screen.jpg"
            />
            <Carousel.Caption
              style={{
                textAlign: isArabic ? "right" : "left",
                paddingTop:"10px",
                marginBottom:"20px",
                
               
            }}>
              <h1>{lan === "English" ? "سهولة في الاستخدام " : "Easy to Use"}</h1>
<p
  style={{
    fontSize: "20px",
    fontWeight: "500",
    direction: lan !== "English" ? "ltr" : "rtl",
  }}>
  {lan === "English"
    ? "توفر واجهة Agribits سهولة في الاستخدام، مما يجعل إدارة المزرعة أكثر بساطة وراحة."
    : "Agribits' interface provides ease of use, making farm management simpler and more convenient."}
</p>

            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <div id="about-section" style={{  padding: "20px" }}>
      <div
        className="aboutContent"
        style={{
          flexDirection: isArabic ? "row-reverse" : "row",
          color: mode === "Light" ? "#000000" : "#ffffff",
          
        }}
      >
        <div className="textContainer">
          <h2 style={{ fontWeight: "bold" }}>
            {lan !== "العربية" ? "معلومات عنا" : "About Us"}
          </h2>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "400",
              direction: lan !== "English" ? "ltr" : "rtl",
            }}
          >
            {lan !== "English"
              ? "Agribits is a dedicated agritech startup focused on revolutionizing poultry farming in Algeria. We provide smart, IoT-based solutions that empower farmers to optimize operations, reduce poultry mortality, and enhance farm management. Our mission is to bring modern technology to small and medium-scale producers, driving growth and sustainability in the industry."
              : " Agribits هي شركة ناشئة متخصصة في التكنولوجيا الزراعية تركز على إحداث ثورة في تربية الدواجن في الجزائر. نحن نقدم حلولاً ذكية تعتمد على إنترنت الأشياء تمكن المزارعين من تحسين العمليات وتقليل نفوق الدواجن وتعزيز إدارة المزرعة. مهمتنا هي جلب التكنولوجيا الحديثة للمنتجين الصغار والمتوسطين، ودفع النمو والاستدامة في الصناعة."}
          </p>
        </div>
        <img
          src="../../images/company.jpg" // Adjust the image path as needed
          alt="Company"
          className="companyImage"
        />
      </div>
    </div>
      <div style={styles.centerImage}>
        <img
          src="../../images/works.png"
          alt="functions"
          style={styles.functionImage}
        />
      </div>

      <FeedbackForm id="feedback-form" mode={mode} lan={lan} />

      <div style={styles.productionSection}>
        <h2
          style={{
            ...styles.productionTitle,
            color: mode === "Light" ? "#000000" : "#f8f9fa",
          }}>
          {lan === "English" ? "منتجاتنا" : "Our Products"}
        </h2>

        <div style={styles.productContainer}>
          <div
            className="product"
            style={styles.product}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <Link
              to="/product"
              style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src="../../images/chikenFarm.png"
                alt="Poultry"
                style={{
                  ...styles.productImage,
                  transform: isHovered ? "scale(1.03)" : "scale(1)",
                  boxShadow: isHovered
                    ? "0 20px 25px rgba(0, 0, 0, 0.3)"
                    : "0 15px 15px rgba(0, 0, 0, 0.2)",
                }}
                className="poultry-image"
              />
              <h3
                style={{
                  color: mode === "Light" ? "#000000" : "#ffffff",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "15px",
                  cursor: "pointer",
                }}>
                {lan === "English" ? "دواجن" : "Poultry"}
              </h3>
            </Link>

          </div>

          <div className="product" style={styles.product}>
            <img
              src="../../images/cattle.png"
              alt="Cattle"
              style={styles.productImage}
            />
            <h3 style={styles.productTitle}>
              {lan === "English" ? "الماشية (قريبًا)" : "Cattle (soon)"}
            </h3>
          </div>
        </div>
      </div>
      <div className="icon-bar">
      <div className="icon-bar">
        <div className="icon-item">
          <FaThermometerHalf size={50} color="#75d840" />
          <p>{lan === "العربية" ? "Monitoring System" : "نظام المراقبة"}</p>
        </div>
        <div className="icon-item">
          <FaBell size={50} color="#75d840" />
          <p>{lan === "العربية" ? "Alert System" : "نظام الإنذار"}</p>
        </div>
        <div className="icon-item">
          <FaMobileAlt size={50} color="#75d840" />
          <p>{lan === "العربية" ? "Mobile App" : "تطبيق الهاتف"}</p>
        </div>
        <div className="icon-item">
          <FaChartBar size={50} color="#75d840" />
          <p>{lan === "العربية" ? "Data Analytics" : "تحليل البيانات"}</p>
        </div>
        <div className="icon-item">
          <FaCloud size={50} color="#75d840" />
          <p>{lan === "العربية" ? "IoT Connectivity" : "اتصال إنترنت الأشياء"}</p>
        </div>
        <div className="icon-item">
          <GiRobotGrab size={50} color="#75d840" />
          <p>{lan === "العربية" ? "Automation" : "تشغيل آلي"}</p>
        </div>
      </div>
    </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "0px 10px 20px",
    paddingTop: "0px",
    fontFamily: "'Klavika', system-ui, sans-serif",
  },
  
  centerImage: {
    width: "100%",
    height: "auto",
    margin: "20px 0",
  },
  functionImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  moreButton: {
    backgroundColor: "#ffcc00",
    border: "none",
    fontWeight:"700",
    padding: "10px 20px",
    fontSize: "1.2rem",
    cursor: "pointer",
    borderRadius: "5px",
  },
  carouselContainer: {
    maxWidth: "70%",
    margin: "0 auto",
    height: window.innerWidth < 768 ? "400px" : "500px", // Example for responsive height
    boxShadow: "0 15px 15px rgba(0, 0, 0, 0.2)",
    borderRadius: "15px",
    overflow: "hidden",
  },
  aboutSection: {
    padding: "60px 20px",
    margin: "40px 0",
  },
  aboutContent: {
    flexDirection: "column", // Column layout for centering
    justifyContent: "space-between",
    alignItems: "center",
  },
  textContainer: {
    flex: 1,
    paddingRight: "20px",
  },
  companyImage: {
    flex: 1,
    width: "60%",
    borderRadius: "10px",
    margin: "0 10%",
    boxShadow: "0 15px 15px rgba(0, 0, 0, 0.3)",
  },
  productionSection: {
    padding: "50px 20px",
  },
  productionTitle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "30px",
  },
  productContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  },
  product: {
    flex: 1,
    textAlign: "center",
    cursor: "default",
  },
  productImage: {
    width: "60%",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    borderRadius: "15px",
  },
  productTitle: {
    marginTop: "15px",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "grey",
  },
  productDescription: {
    fontSize: "1rem",
    marginTop: "20px",
    color: "grey",
  },
};

export default Farming;
