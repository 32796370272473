import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons CSS
import { GiChicken, GiSheep } from "react-icons/gi";
import { Link, useLocation } from 'react-router-dom';

function Navb({ lang, lan, Mode, mode }) {
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const { pathname } = useLocation()
  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Check if the user is scrolling down
    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavbar(false); // Hide navbar
    } else {
      setShowNavbar(true);
    }

    // Update last scroll position
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`fixed-top ${showNavbar ? 'show' : 'hide'}`}
      style={{
        transition: 'top 0.7s', // Smooth transition
        top: showNavbar ? '0' : '-100px', // Hide or show the navbar based on scroll
        padding: "0",
        background: mode==="Dark"?"linear-gradient(rgb(67, 83, 52, 0.7) 30%, rgba(255, 255, 255, 0))":"linear-gradient( rgba(0, 0, 0, 0.4) 30%, rgba(255, 255, 255, 0))",
      }}
    >
      <Container style={{ marginLeft: "0" }}>
        <Navbar.Brand href="/">
          <img
            src={"../../images/logo.png"}
            alt="Agribits"
            style={{ height: '120px', marginLeft: "20px" }} // Adjust the height as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link
  as={Link} // Use Link from react-router-dom for in-app navigation
  to="/"    // Replace href with to for Link
  style={{
    fontWeight: 'bold',
    fontSize: '20px',
    color: pathname === "/" ? "#FEFF9F" : mode === "Light" ? "white" : "#FAF1E4"
  }}
>
  {lan === "العربية" ? "Home" : "الصفحة الرئيسية"}
</Nav.Link>
        {     pathname==="/"&&  <Nav.Link 
             onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              const feedbackForm = document.getElementById("about-section");
              if (feedbackForm) {
                feedbackForm.scrollIntoView({ behavior: "smooth" });
              }
            }}
              style={{ 
                fontWeight: 'bold', 
                fontSize: '20px', 
                color: mode === "Light" ? "white" : "#FAF1E4" 
              }}
            >
              {lan === "العربية" ? "About ":"معلومات عنا " }
            </Nav.Link>}
         
            <NavDropdown 
              title={
                <span 
                  style={{ 
                    fontWeight: 'bold', 
                    fontSize: '20px',
                    color: pathname === "/product" ? "#FEFF9F" : mode === "Light" ? "white" : "#FAF1E4"
                   
                  }}
                >
                  {lan === "العربية" ?  "Product ":"المنتج"}
                </span>
              } 
              id="collapsible-nav-dropdown"
            >
                <NavDropdown.Item 
  style={{ 
    fontSize: '16px', 
    color: mode === "Light" ? "#000000" : "white", 
    fontWeight: "500",
    backgroundColor: mode === "Light" ?  "#f8f9fa" : "#3C3D37",
    textAlign: lan === "العربية" ? "left" : "right",
  }}
>
  <Link to="/product" onClick={()=> window.scrollTo(0, 0)} style={{ textDecoration: 'none', color: mode === "Light" ? "#000000" : "white" }}>
    {lan === "العربية" ? "Poultry" : "الدواجن"} <GiChicken size={25} color="#75d840" />
  </Link>
</NavDropdown.Item>

              <NavDropdown.Item 
                href="/" 
                style={{ 
                  fontSize: '16px', 
                  color: "grey", 
                  backgroundColor: mode === "Light" ? "#f8f9fa" : "#3C3D37", 
                  textAlign: lan === "العربية" ? "left" : "right",
                  pointerEvents: "none", // Disable click action
                  cursor: "default", // Set cursor to default
                }}
              >
               {lan === "العربية" ?  "Cattle (soon)":"(قريبا)الماشية" } <GiSheep size={25} color="#75d840" />
              </NavDropdown.Item>
            </NavDropdown>
   {        pathname==="/"&&    <Nav.Link 
  onClick={(e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    const feedbackForm = document.getElementById("feedback-form");
    if (feedbackForm) {
      feedbackForm.scrollIntoView({ behavior: "smooth" });
    }
  }}
  style={{ 
    fontWeight: 'bold', 
    fontSize: '20px', 
    color: mode === "Light" ? "white" : "#FAF1E4" 
  }}
>
  {lan === "العربية" ?  " Feedback+" :  " +تعليق"} 
</Nav.Link>}

          </Nav>
          <Nav>
            <Nav.Link 
              onClick={lang} 
              style={{ 
                fontWeight: 'bold', 
                fontSize: '18px', 
                color: mode === "Light" ? "white" : "#FAF1E4" 
              }}
            >
              {lan}
            </Nav.Link>
            <Nav.Link 
              eventKey={2} 
              onClick={Mode} 
              style={{ 
                fontWeight: 'bold', 
                fontSize: '18px', 
                color: mode === "Dark" ? "white" : "black" 
              }}
            >
              {mode === "Dark" ? (
                <i className="bi bi-sun" style={{ fontSize: '25px',marginLeft:"10px" }} /> // Sun icon
              ) : (
                <i className="bi bi-moon" style={{ fontSize: '25px',marginLeft:"10px" }} /> // Moon icon
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navb;
