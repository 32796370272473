import React from 'react';
import { FaTemperatureHigh, FaBell, FaNetworkWired, FaMobileAlt, FaChartLine, FaExpandArrowsAlt } from 'react-icons/fa'; // Import React Icons
import '../styles/ProjectsDropdown.css';

const Products = ({ lan, mode }) => {
  // Define text color based on the mode
  const textColor = mode === "Light" ? "#000" : "#fff";

  return (
    <div className={`product-container ${lan === "English" ? 'rtl' : ''}`} style={{ backgroundColor: mode === "Light" ? "#f8f9fa" : "#3C3D37", color: textColor }}>
      {/* Title */}
      <div className="product-title">
        <h1>{lan === "English" ? 'مراقب المزرعة الذكي' : 'Smart Farm Monitor'}</h1>
      </div>
      <div className="spec-text" style={{ marginRight: lan === "English" ? '30px' : '0px', marginLeft: lan === "English" ? '0px' : '30px',margin:"40px"}}>
      ●{  lan === "English" ?` Agribits نظام إدارة الدواجن الذكي القائم على الإنترنت لمساعدة
    المزارعين على مراقبة والتحكم في بيئة مزرعتهم بكفاءة. مع تحليلات البيانات في الوقت الحقيقي،
    والوصول عن بعد عبر تطبيق الهاتف المحمول، والتنبيهات الآلية، يعزز Agribits اتخاذ القرارات،
    ويقلل من معدلات الوفيات، ويحسن عمليات المزرعة لتحقيق إنتاجية أفضل.`:"  Agribits offers a smart IoT-based poultry management system designed to help farmers monitor and control their farm environment efficiently. With real-time data analytics, remote access via mobile app, and automated alerts, Agribits enhances decision-making, reduces mortality rates, and optimizes farm operations for better productivity."}
</div>
      {/* Specifications Section */}

      <div className="product-description">
        <h2 style={{ direction: lan === "English" ? "rtl" : "ltr", textAlign: lan === "English" ? "right" : "left", color: textColor }}>
          {lan === "English" ? 'المواصفات:' : 'Specifications:'}
        </h2>

        {/* First Specification */}
        <div className="spec-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px',textAlign: lan==="English" ? "right" : "left",}}>
          <FaTemperatureHigh   size={48} style={{ color: "#75d840" }} />
          <div className="spec-text" style={{ marginRight: lan === "English" ? '30px' : '0px', marginLeft: lan === "English" ? '0px' : '30px',}}>
            ● <strong>{lan === "English" ? 'قدرات المراقبة' : 'Monitoring Capabilities'}</strong>: {lan === "English"
              ? 'مراقبة مستمرة لدرجة الحرارة والرطوبة وجودة الهواء ومستويات الأعلاف.'
              : 'Continuous monitoring of temperature, humidity, air quality, and feed levels.'}
          </div>
          <div className="spec-image image1">
            <img src="../../images/Device.png" style={{boxShadow: "0 15px 15px rgba(0, 0, 0, 0.3)"}} alt={lan === "English" ? 'قدرات المراقبة' : 'Monitoring Capabilities'} />
          </div>
        </div>

        {/* Second Specification */}
        <div className="spec-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <FaBell size={48} style={{ marginRight: lan === "English" ? '95px' : '50px', marginLeft: lan === "English" ? '50px' : '96px', color: "#75d840" }} />
          <div className="spec-image image2">
            <img src="../../images/PCpic.png" style={{boxShadow: "0 15px 15px rgba(0, 0, 0, 0.3)"}} alt={lan === "English" ? 'نظام التنبيه' : 'Alert System'} />
          </div>
          <div className="spec-text">
            ● <strong>{lan === "English" ? 'نظام التنبيه' : 'Alert System'}</strong>: {lan === "English"
              ? 'تنبيهات فورية عبر الرسائل النصية والإشعارات في حالة التغيرات غير الطبيعية.'
              : 'Immediate alerts via SMS and app notifications in case of abnormal changes.'}
          </div>
        </div>

        {/* Third Specification */}
        <div className="spec-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px',textAlign: lan==="English" ? "right" : "left",}}>
          <FaNetworkWired size={48} style={{ color: "#75d840" }} />
          <div className="spec-text" style={{ marginRight: lan === "English" ? '30px' : '0px', marginLeft: lan === "English" ? '0px' : '30px',}}>
            ● <strong>{lan === "English" ? 'تكامل إنترنت الأشياء' : 'IoT Integration'}</strong>: {lan === "English"
              ? 'اتصال سلس مع معدات المزرعة ومزامنة البيانات في الوقت الفعلي.'
              : 'Seamless connectivity with farm equipment and real-time syncing of data.'}
          </div>
          <div className="spec-image image3">
            <img src="../../images/screen.png" style={{boxShadow: "0 15px 15px rgba(0, 0, 0, 0.3)"}}  alt={lan === "English" ? 'تكامل إنترنت الأشياء' : 'IoT Integration'} />
          </div>
        </div>

        {/* Additional Specifications without Images */}
        <div className="spec-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px',textAlign: lan==="English" ? "right" : "left",}}>
          <FaMobileAlt size={48} style={{ color: "#75d840" ,marginTop:"20px"}} />
          <div className="spec-text" style={{ marginRight: lan === "English" ? '30px' : '0px', marginLeft: lan === "English" ? '0px' : '30px',marginTop:"20px"}}>
            ● <strong>{lan === "English" ? 'تطبيق الهاتف المحمول' : 'Mobile Application'}</strong>: {lan === "English"
              ? 'التحكم الكامل من خلال تطبيق سهل الاستخدام على أنظمة Android وiOS.'
              : 'Full control through a user-friendly mobile app, available on Android and iOS.'}
          </div>
        </div>

        <div className="spec-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px',textAlign: lan==="English" ? "right" : "left",}}>
          <FaChartLine size={48} style={{ color: "#75d840" }} />
          <div className="spec-text" style={{ marginRight: lan === "English" ? '30px' : '0px', marginLeft: lan === "English" ? '0px' : '30px',}}>
            ● <strong>{lan === "English" ? 'تحليل البيانات' : 'Data Analytics'}</strong>: {lan === "English"
              ? 'تحليل البيانات السابقة لاتخاذ قرارات أكثر ذكاءً والتنبؤ بالأمراض.'
              : 'Historical data analysis for smarter decision-making and disease prediction.'}
          </div>
        </div>

        <div className="spec-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px',textAlign: lan==="English" ? "right" : "left",}}>
          <FaExpandArrowsAlt size={48} style={{ color: "#75d840" }} />
          <div className="spec-text" style={{ marginRight: lan === "English" ? '30px' : '0px', marginLeft: lan === "English" ? '0px' : '30px',}}>
            ● <strong>{lan === "English" ? 'قابلية التوسع' : 'Scalability'}</strong>: {lan === "English"
              ? 'مناسب للمزارع الصغيرة والمتوسطة والكبيرة، مع ميزات قابلة للتخصيص.'
              : 'Suitable for small, medium, and large poultry farms, with customizable features.'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
