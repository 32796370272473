import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styles from "../styles/Footer.module.css"; // Import the CSS module
import { useLocation, useNavigate } from "react-router-dom"; // For navigation

const Footer = ({ lan, mode }) => {
  const form = useRef();
  const navigate = useNavigate(); // Hook for programmatic navigation
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const { pathname } = useLocation();

  const handleSendEmail = (e) => {
    e.preventDefault();

    if (email.trim() === "" || message.trim() === "") {
      setModalMessage(
        lan === "العربية"
          ? "Please fill in both email and message fields."
          : "يرجى ملء كل من البريد الإلكتروني وحقل الرسالة."
      );
      setModalType("error");
      setIsModalOpen(true);
      return;
    }

    setIsSending(true);

    emailjs
      .sendForm(
        "service_bgs34wk",
        "template_6bc43an",
        form.current,
        "jm56_ibSKRykP9dsR"
      )
      .then(
        () => {
          setIsSending(false);
          setModalMessage(
            lan === "العربية"
              ? "Message sent successfully."
              : "تم إرسال الرسالة بنجاح!"
          );
          setModalType("success");
          setIsModalOpen(true);
          form.current.reset();
          setMessage("");
          setEmail("");
        },
        () => {
          setIsSending(false);
          setModalMessage(
            lan === "العربية"
              ? "Failed to send message. Please try again."
              : "فشل في إرسال الرسالة. يرجى المحاولة مرة أخرى."
          );
          setModalType("error");
          setIsModalOpen(true);
        }
      );
  };

  // Define the background color based on the mode
  const backgroundColor = mode === "Dark" ? "#333" : "#fff";
  const textColor = mode === "Dark" ? "#fff" : "#000";

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          {/* Contact form */}
          <div className={styles.contactSection}>
            <h4>{lan === "العربية" ? "Quick Contact" : "اتصال سريع"}</h4>
            <form
              ref={form}
              onSubmit={handleSendEmail}
              className={styles.contactForm}>
              <input
                type="email"
                name="user_email"
                placeholder={
                  lan === "العربية" ? "Your Email" : "بريدك الإلكتروني"
                }
                className={styles.inputField}
                value={email}
                style={{
                  textAlign: lan === "العربية" ? "left" : "right",
                  backgroundColor: backgroundColor,
                  color: textColor,
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <textarea
                id="message"
                name="message"
                rows="3"
                style={{
                  textAlign: lan === "العربية" ? "left" : "right",
                  backgroundColor: backgroundColor,
                  color: textColor,
                }}
                placeholder={lan === "العربية" ? "Your Message" : "رسالتك"}
                className={styles.textarea}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSending}>
                {isSending
                  ? lan === "العربية"
                    ? "Sending..."
                    : "يتم الإرسال..."
                  : lan === "العربية"
                  ? "Send"
                  : "إرسال"}
              </button>
            </form>
          </div>

          {/* Links Section */}
          <div
            className={styles.linksSection}
            style={{ textAlign: lan === "العربية" ? "left" : "right" }}>
            {pathname === "/" && (
              <h4>{lan === "العربية" ? "Links" : "الروابط"}</h4>
            )}
            <ul
              className={styles.footerLinks}
              style={{ textAlign: lan === "العربية" ? "left" : "right" }}>
              <li>
                {pathname === "/" && (
                  <div
                    className={styles.linkItem}
                    onClick={() => {
                      const aboutSection = document.getElementById("about-section");
                      if (aboutSection) {
                        aboutSection.scrollIntoView({ behavior: "smooth" });
                      }
                    }}>
                    {lan === "العربية" ? "About Us" : "معلومات عنا"}
                  </div>
                )}
              </li>
              <li>
                <div
                  className={styles.linkItem}
                  onClick={() => {navigate("/product");
                    window.scrollTo(0, 0);
                  }}
                  style={{textDecoration: pathname === "/product" ? "underline" : null}}>
                  {lan === "العربية" ? "Products" : "المنتجات"}
                </div>
              </li>
              <li>
                <div
                  className={styles.linkItem}
                  onClick={() => {navigate("/");
                    window.scrollTo(0, 0);

                  }}
                  style={{
                    textDecoration: pathname === "/" ? "underline" : null
                  }}>
                  {lan === "العربية" ? "Home" : "الصفحة الرئيسية"}
                </div>
              </li>
              {pathname === "/" && (
                <li>
                  <div
                    className={styles.linkItem}
                    onClick={() => {
                      const feedbackForm = document.getElementById("feedback-form");
                      if (feedbackForm) {
                        feedbackForm.scrollIntoView({ behavior: "smooth" });
                      }
                    }}>
                    {lan === "العربية" ? "Feedback+" : "+تعليق"}
                  </div>
                </li>
              )}
            </ul>
          </div>

          {/* Contact Info Section */}
          <div
            className={styles.infoSection}
            style={{ textAlign: lan === "العربية" ? "left" : "right" }}>
            <p>
              {lan === "العربية"
                ? "Phone: +213-792276011"
                : " +213-792276011 :الهاتف"}
            </p>
            <p>
              {lan === "العربية"
                ? "Email: company.agribits@gmail.com"
                : "company.agribits@gmail.com :البريد الإلكتروني"}
            </p>
          </div>
        </div>

        {/* Copyright */}
        <div className={styles.copyright}>
          <p>
            Agribits™ Trademark of Agribits <br />
            &copy; 2024 Agribits.{" "}
            {lan === "العربية" ? "All rights reserved." : "جميع الحقوق محفوظة."}
          </p>
        </div>
      </footer>

      {/* Modal for sending status */}
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h4>
              {modalType === "success"
                ? lan === "العربية"
                  ? "Success"
                  : "تم الارسال"
                : lan === "العربية"
                ? "Error"
                : "خطأ"}
            </h4>
            <p>{modalMessage}</p>
            <button
              className={styles.closeModalButton}
              onClick={() => setIsModalOpen(false)}>
              {lan === "العربية" ? "Close" : "إغلاق"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
